import { GlobalStyles, useTheme } from '@mui/material'

export const TunaGlobalStyles = () => {
  const theme = useTheme()
  const styles = {
    '::-moz-selection': { background: theme.vars.palette.action.selected },
    '::selection': { background: theme.vars.palette.action.selected },

    'body, html': {
      backgroundColor: theme.vars.palette.background.default,
      '--background-color': theme.vars.palette.background.default,
      fontFamily: theme.typography.fontFamily,
    },

    'h1, h2, h3, h4, h5, h6': {
      color: theme.vars.palette.text.primary,
    },
    p: {
      ...theme.typography.body1,
    },
    h1: {
      ...theme.typography.h1,
    },
    h2: {
      ...theme.typography.h2,
    },
    h3: {
      ...theme.typography.h3,
    },
    h4: {
      ...theme.typography.h4,
    },
    h5: {
      ...theme.typography.h5,
    },
    h6: {
      ...theme.typography.h6,
    },
  }

  return <GlobalStyles styles={styles} />
}

export default TunaGlobalStyles
