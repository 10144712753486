import React, { type FC } from 'react'

export type DocsPage = {
  label: string
  path: string
  Component?: FC
  children?: DocsPage[]
}

/** List of docs that we use to generate both the route and the TOC */
export const docPages: DocsPage[] = [
  {
    label: 'Tunasong Documentation',
    path: '.',
    Component: React.lazy(() => import('./pages/index.mdx')),
  },
  {
    label: 'Getting Started',
    path: 'getting-started',
    Component: React.lazy(() => import('./pages/getting-started.mdx')),
  },
  {
    label: 'Recording',
    path: 'recording',
    Component: React.lazy(() => import('./pages/recording/index.mdx')),
    children: [
      {
        label: 'Clips',
        path: 'clips',
        Component: React.lazy(() => import('./pages/recording/clips.mdx')),
      },
    ],
  },
]
