import { Grid, StyledEngineProvider, useColorScheme } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import * as Sentry from '@sentry/react'
import { ErrorGeneral } from '@tunasong/tunasong-ui'
import { TunaTheme } from '@tunasong/ui-lib'
import { VendorServices } from '@tunasong/vendor'
import { type FC } from 'react'
import TunaGlobalStyles from '../../app-global-styles.js'
import { FullPageLayout } from '../../di/layouts/full-page.js'
import type { DocsPage } from '../doc-pages.js'
import TOC from './toc.js'

export interface DocsContainerProps {
  name: string
  pages: DocsPage[]
  Component?: FC
  componentProps?: Record<string, unknown>
}

export const DocsContainer: FC<DocsContainerProps> = props => {
  const { pages, Component, componentProps } = props
  const { colorScheme } = useColorScheme()

  return (
    <main className="flex flex-col flex-1  bg-gradient-to-b p-8 ">
      <Sentry.ErrorBoundary fallback={ErrorGeneral}>
        <StyledEngineProvider injectFirst>
          <VendorServices>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={navigator.language}>
              <TunaTheme colorScheme={colorScheme}>
                <TunaGlobalStyles />
                <CssBaseline />

                <FullPageLayout>
                  <Grid container>
                    <Grid size={{ xs: 3, md: 3 }}>
                      <TOC pages={pages} />
                    </Grid>
                    <Grid size={{ xs: 9, md: 9 }}>
                      {Component ? <Component {...componentProps} /> : 'No component'}
                    </Grid>
                  </Grid>
                </FullPageLayout>
              </TunaTheme>
            </LocalizationProvider>
          </VendorServices>
        </StyledEngineProvider>
      </Sentry.ErrorBoundary>
    </main>
  )
}

export default DocsContainer
