import type { DefaultGlobalContext, PluginConfigSet, PluginRepository, TunaPlugin } from '@tunasong/plugin-lib'
import type { EntityOrElement } from '@tunasong/schemas'
import { configurePlugins } from './plugin-collection.js'
import { pluginSets } from './plugins.js'

/** We want to dynamically load plugins when used */

export class Repository implements PluginRepository {
  #pluginMap = new Map<string, TunaPlugin<EntityOrElement, DefaultGlobalContext>>()
  #pluginCollections: Partial<Record<string, TunaPlugin<EntityOrElement, DefaultGlobalContext>[]>> = {}
  #pluginCollectionPromises: Partial<Record<string, Promise<TunaPlugin<EntityOrElement, DefaultGlobalContext>[]>>> = {}

  loadCollection = async (type: PluginConfigSet) => {
    if (this.#pluginCollectionPromises[type]) {
      return this.#pluginCollectionPromises[type]
    }

    const pluginsPromise = configurePlugins(pluginSets[type]).then(coll => {
      this.#pluginCollections[type] = coll

      // We special case 'all' to load all plugins into the map
      if (type === 'all') {
        for (const plugin of coll) {
          this.#pluginMap.set(plugin.key, plugin)
        }
      }
      return coll
    })
    this.#pluginCollectionPromises[type] = pluginsPromise

    return pluginsPromise
  }

  getCollection = (type: PluginConfigSet) => this.#pluginCollections[type]
  getPlugin = (key: string) => this.#pluginMap.get(key)
}
