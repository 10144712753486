import type { TunaThunkDispatch } from '@tunasong/redux'
import { entitiesApi } from '@tunasong/redux'
import type { EdgeType, Entity, Persisted } from '@tunasong/schemas'
import type { Graph } from '../graph.js'

interface RTKReduxGraphOptions {
  dispatch: TunaThunkDispatch
}

export const createRTKReduxGraph = ({ dispatch }: RTKReduxGraphOptions): Graph => {
  const createEntity = async <T extends Entity = Entity>({
    entity,
    parent,
  }: {
    entity: T
    parent: Persisted<Entity>
  }) =>
    dispatch(
      entitiesApi.endpoints.createEntity.initiate({
        entity,
        parent,
      })
    ).unwrap()

  const getEntity = async (id: string) => dispatch(entitiesApi.endpoints.loadEntity.initiate({ id })).unwrap()

  const updateEntity = <T extends Entity = Entity>({
    id,
    partialEntity,
    eTag = null,
  }: {
    id: string
    partialEntity: Partial<T>
    eTag: string | null
  }) => dispatch(entitiesApi.endpoints.updateEntity.initiate({ id, partialEntity, eTag })).unwrap()

  const deleteEntity = (entity: Persisted<Entity>) =>
    dispatch(entitiesApi.endpoints.deleteEntity.initiate({ entity })).unwrap()

  /** Edges */
  const createEntityEdge = async ({
    source,
    target,
    relation,
  }: {
    source: string
    target: string
    relation: EdgeType
  }) =>
    dispatch(
      entitiesApi.endpoints.createEntityEdge.initiate({
        edge: {
          source,
          target,
          relation,
        },
      })
    ).unwrap()

  return {
    createEntity,
    createEntityEdge,
    getEntity,
    updateEntity,
    deleteEntity,
  }
}
