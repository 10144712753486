import { EditorsContext } from '@tunasong/editor'
import { PluginContext } from '@tunasong/plugin-lib'
import type { FC } from 'react'
import React from 'react'
import { useRegisterMimeTypesFromPlugins } from '../hooks/register-mime.js'
import { editorContext } from './editors.js'
import { Repository } from './plugin-repository.js'

const MimeTypeComponent = () => {
  /**
   * @todo this seems like a candidate for a refactor
   * Register mime types in Redux
   */
  useRegisterMimeTypesFromPlugins()
  return null
}

/** Inject classes and values in the relevant context(s) */
export const DIContainer: FC<{ children?: React.ReactNode; pluginRepository: Repository | null }> = ({
  children,
  pluginRepository,
}) => (
  <PluginContext.Provider value={pluginRepository}>
    <MimeTypeComponent />
    <EditorsContext.Provider value={editorContext}>{children}</EditorsContext.Provider>
  </PluginContext.Provider>
)
