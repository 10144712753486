import type { createBrowserRouter } from '@tunasong/ui-lib'
import { docPages, type DocsPage } from './doc-pages.js'
import DocsContainer from './components/docs-container.js'

// React Router routes

type DocsRoute = Parameters<typeof createBrowserRouter>['0'][number]

const createRoutes = (page: DocsPage, path = ''): DocsRoute[] => {
  const fullPath = `${path}/${page.path}`
  return [
    {
      path: fullPath,
      element: <DocsContainer pages={docPages} name={page.label} Component={page.Component} />,
    },
    ...(page.children?.map(child => createRoutes(child, fullPath)) ?? []).flat(),
  ]
}

/** @note we don't use nested routes because the app is not designed for it. So we flat the routes */
export const docsRouter: DocsRoute[] = [
  {
    path: 'docs',
    element: <DocsContainer pages={docPages} name="Tunasong Documentation" />,
  },
  ...docPages.map(doc => createRoutes(doc, 'docs')).flat(),
]
