import { TreeItem } from '@mui/x-tree-view'
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView'
import { type FC, type MouseEvent } from 'react'
import type { DocsPage } from '../doc-pages.js'
import { useNavigate } from '@tunasong/ui-lib'

export interface TOCProps {
  pages: DocsPage[]
}

const getTreeItem = (page: DocsPage, path: string = '', navigate: ReturnType<typeof useNavigate>) => {
  const fullPath = path ? `${path}/${page.path}` : page.path
  const handleNavigate = (ev: MouseEvent) => {
    ev.preventDefault()
    const to = `/docs/${fullPath}`
    navigate(to)
  }
  return (
    <TreeItem key={fullPath} itemId={fullPath} label={page.label} onClick={handleNavigate}>
      {page.children?.map(child => getTreeItem(child, fullPath, navigate))}
    </TreeItem>
  )
}

export const TOC: FC<TOCProps> = props => {
  const { pages } = props
  const navigate = useNavigate()

  return <SimpleTreeView>{pages.map(page => getTreeItem(page, '', navigate))}</SimpleTreeView>
}

export default TOC
