/** Sync with Service Worker */

import { logger } from '@tunasong/models'
import { APIMap, useThunkDispatch, type APIName } from '@tunasong/redux'
import { useEffect } from 'react'
import invariant from 'tiny-invariant'
import type { SWSyncMessage } from './message.js'

export const useSWSync = () => {
  const dispatch = useThunkDispatch()

  useEffect(() => {
    const serviceWorker = navigator?.serviceWorker
    if (!(serviceWorker && 'caches' in window)) {
      return
    }

    const handleSWMessage = async (msg: MessageEvent<{ payload: SWSyncMessage }>) => {
      const { cacheName, updatedURL, rtkQueryArgs, rtkQueryEndpointName, rtkQueryApiName } = msg.data.payload

      // logger.debug(`Service Worker Sync: ${cacheName} - ${updatedURL}`, {
      //   rtkQueryEndpointName,
      //   rtkQueryArgs,
      //   rtkQueryApiName,
      // })

      // Not all queries are cached
      if (!(rtkQueryArgs && rtkQueryEndpointName)) {
        return
      }

      /** Match the data from the cache */
      const data = await caches.match(updatedURL, { cacheName })
      if (!data) {
        logger.warn(`Service Worker Sync: Cache entry not found: ${cacheName} - ${updatedURL}`)
        return
      }

      // Map to API
      const api = APIMap[rtkQueryApiName as APIName]
      invariant(api, `API not found: ${rtkQueryApiName}`)

      // Load the data from the cache
      const json = await data.json()

      /** Update the store */
      if (rtkQueryArgs && rtkQueryEndpointName) {
        const args = JSON.parse(decodeURIComponent(rtkQueryArgs))
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const upsertQueryData = api.util.upsertQueryData as (endpointName: string, args: any, data: any) => any
        logger.debug(`Updating RTK Query data: ${rtkQueryApiName} - ${rtkQueryEndpointName}`, { args, json })
        dispatch(upsertQueryData(rtkQueryEndpointName, args, json))
      }
    }

    serviceWorker.addEventListener('message', handleSWMessage)
    return () => {
      serviceWorker.removeEventListener('message', handleSWMessage)
    }
  }, [dispatch])
}
